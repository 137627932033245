import React, { Component } from "react";

import ReactHtmlParser from "react-html-parser"

import Layout from "../components/layou_en"
import Banner from "../components/others/banner"
import ReactMarkdown from 'react-markdown'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import { StaticQuery, graphql } from "gatsby"

const objectGraphql = graphql`

{
  strapiInfraestructura(locale: {eq: "en"}) {
    Banner {
      fondo {
        url
      }
      url {
        enlace
        titulo
        id
      }
      titulo
      id
    }
    Embarcaciones {
      item {
        conservacion
        dato
        id
        tipo
        titulo
        img {
          url
        }
      }
      description {
        descripcion
        id
        titulo
        img {
          url
        }
      }
    }
    Laboratorios {
      descripcion {
        descripcion
        id
        img {
          url
        }
        titulo
      }
      id
      item {
        descripcion
        id
        img {
          url
        }
      }
    }
    Plantas {
      descripcion {
        descripcion
        id
        img {
          url
        }
        titulo
      }
      id
      item {
        descripcion
        id
        img {
          url
        }
      }
    }
  }
}


`


class Infrastructure extends Component {

  render() {


    return (
      <StaticQuery
        query={objectGraphql}
        render={data => (
          <React.Fragment>
            <Layout props={this.props.props}>
              <Banner banner={data.strapiInfraestructura.Banner} />
              <section id="gestion" className="padding-section-int">
                <div id="embarcaciones" className="container">
                  <div className="row margin-b-80">
                    <div className="col-md-8">
                      <h3> {
                        data.strapiInfraestructura.Embarcaciones.description.titulo}
                      </h3>
                      <ReactMarkdown>{data.strapiInfraestructura.Embarcaciones.description.descripcion}</ReactMarkdown>
                      <hr />
                      <div className="row">
                        <div className="col-md-12">
                          <AliceCarousel mouseTracking
                            responsive={{
                              0: { items: 1 },
                              568: { items: 2 },
                              1024: { items: 3 },
                            }}
                            controlsStrategy="alternate"
                            infinite
                            
                            autoPlayStrategy="none"
                            autoPlayInterval={3500}
                            animationDuration={500}
                            animationType="fadeout"
                            keyboardNavigation
                            disableDotsControls
                          >
                            {
                              data.strapiInfraestructura.Embarcaciones.item.map((item,index) => {
                                return (
                                  <div key={index} style={{ marginRight: '20px'}}  className="item">
                                    {console.log(item)}
                                    <h5>{item.titulo}</h5>
                                    <p>{item.conservacion}<br />
                                    {((item.tipo).trim()).toLowerCase() === 'rsw' ?    
                                     <i className="fa fa-snowflake-o">&nbsp;</i> : ''}
                                     {item.tipo}<br />
                                      m<sup>3</sup> {item.dato}</p>

                                    <p><img alt={item.img.url}  className="img-fluid" src={item.img.url} /></p>
                                  </div>
                                )
                              })
                            }
                          </AliceCarousel>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 text-center">
                      <p><img src={data.strapiInfraestructura.Embarcaciones.description.img.url} className="img-fluid" alt={data.strapiInfraestructura.Embarcaciones.description.img.url} /></p>
                    </div>
                  </div>
                </div>

                <div id="laboratorio" className="container">
                  <div className="row margin-b-80">
                    <div className="col-md-8">
                      <h3> {
                        data.strapiInfraestructura.Laboratorios.descripcion.titulo}
                      </h3>
                      <ReactMarkdown>{data.strapiInfraestructura.Laboratorios.descripcion.descripcion}</ReactMarkdown>
                      <hr />
                      <div className="row">
                        <div className="col-md-12">
                        <AliceCarousel mouseTracking
                            responsive={{
                              0: { items: 1 },
                              568: { items: 2 },
                              1024: { items: 3 },
                            }}
                            controlsStrategy="alternate"
                            infinite
                            
                            autoPlayStrategy="none"
                            autoPlayInterval={3500}
                            animationDuration={500}
                            animationType="fadeout"
                            keyboardNavigation
                            disableDotsControls
                          >
                            {
                              data.strapiInfraestructura.Laboratorios.item.map((item,index) => {
                                return (
                                  <div key={index} style={{ marginRight: '20px'}}  className="item">
                                    {console.log(item)}
                                    <h5>{item.titulo}</h5>
                                    <ReactMarkdown>{item.descripcion}</ReactMarkdown>
                                     

                                    <img alt={item.img.url}  className="img-fluid" src={item.img.url} />
                                  </div>
                                )
                              })
                            }
                          </AliceCarousel>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 text-center">
                      <p><img src={data.strapiInfraestructura.Laboratorios.descripcion.img.url} className="img-fluid" alt={data.strapiInfraestructura.Laboratorios.descripcion.img.url} /></p>
                    </div>
                  </div>
                </div>

                <div id="plantas" className="container">
                  <div className="row margin-b-80">
                    <div className="col-md-8">
                      <h3>{ReactHtmlParser(data.strapiInfraestructura.Plantas.descripcion.titulo)}
                      </h3>
                      <ReactMarkdown>{data.strapiInfraestructura.Plantas.descripcion.descripcion}</ReactMarkdown>
                      <hr />
                      <div className="row">
                        <div className="col-md-12">
                        <AliceCarousel mouseTracking
                            responsive={{
                              0: { items: 1 },
                              568: { items: 2 },
                              1024: { items: 3 },
                            }}
                            controlsStrategy="alternate"
                            infinite
                            
                            autoPlayStrategy="none"
                            autoPlayInterval={3500}
                            animationDuration={500}
                            animationType="fadeout"
                            keyboardNavigation
                            disableDotsControls
                          >
                            {
                              data.strapiInfraestructura.Plantas.item.map((item,index) => {
                                return (
                                  <div key={index} style={{ marginRight: '20px', minHeight:'385px' }}  className="item">
                                    <h5>{item.titulo} </h5>
                                   <div className='lh-p p-firs-2' style={{minHeight:'188px'}}>
                                    <ReactMarkdown  skipHtml={true} >{item.descripcion}</ReactMarkdown>
                                    </div>

                                    <img alt={item.img.url}  className="img-fluid" src={item.img.url} />
                                  </div>
                                )
                              })
                            }
                          </AliceCarousel>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 text-center">
                      <p><img src={data.strapiInfraestructura.Plantas.descripcion.img.url} className="img-fluid" alt={data.strapiInfraestructura.Plantas.descripcion.img.url} /></p>
                    </div>
                  </div>
                </div>
              </section>
            </Layout>
          </ React.Fragment>
        )}
      />
    )
  }
}

export default Infrastructure
